import React, { useState, useEffect } from 'react';
import '../Card.css';
import apiHelper from '../../../utils/apiHelpers';
import Footer from '../../common/Footer';
import CardPurchaseList from './CardPurchaseList';
import CardFilters from '../CardFilters';
import Loader from '../../common/Loader';
import CardsNotFoundList from '../CardsNotFoundList';
import cardUtils from '../../../utils/cardUtils';
import { Alert } from 'react-alert';
import TotalAmount from '../../common/TotalAmount';
import formatter from '../../../utils/formatter';

const FindBulk = () => {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [cardsNotFound, setCardsNotFound] = useState([]);
  const [sortedCards, setSortedCards] = useState([]);
  const [cardName, setCardName] = useState('');
  const [filters, setFilters] = useState({});
  const [showCards, setShowCards] = useState(true);

  useEffect(() => {
    if (cardName.length >= 4) {
      let currCardName = cardName;
      setTimeout(function () {
        if (currCardName == cardName) getCardByName(cardName.trim());
      }, 1000);
    }
  }, [cardName, filters]);

  useEffect(() => {
    if (cards.length) {
      setSortedCards(cardUtils.groupCardsByStore(cards));
    }
  }, [cards, filters, showCards]);

  const getCardByName = card_name => {
    setLoading(true);
    filters.limit = 1000;
    filters.where.name = card_name;

    apiHelper
      .getCardsBulk(filters)
      .then(cards => {
        if (cards.data.cards.length > 0) {
          setCards(
            cards.data.cards.filter(
              card =>
                card.cardPrices &&
                card.cardPrices
                  .map(cardPrice => cardPrice.stock)
                  .reduce((previous, current) => {
                    return previous + current;
                  }, 0) > 0,
            ),
          );
        } else {
          setCards([]);
          setSortedCards([]);
        }
        setCardsNotFound(cards.data.cardsNotFound);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400 || statusCode === 422 || statusCode === 204) {
            alert.error('Error al cargar datos de las cartas');
          } else if (error.request) {
            alert.error('Sin respuesta del Servidor');
          } else {
            alert.error(error.message);
          }
        }
        setLoading(false);
      });
  };

  const handleChange = e => {
    setCardName(e.target.value.toString());
  };

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="container">
        <h1 className="text-position-title text-center">
          <i className="fas fa-search mr-2"></i>
          <u>Ingrese un listado de cartas</u>
        </h1>

        <h4 className="text-white">
          El formato debe ser "[cantidad de unidades] [nombre de carta]"
          (Formato MTGO) o bien "[nombre de carta]"
        </h4>
        <div className="row text-white">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h5>Ejemplo de formato 1:</h5>
            <ul>
              <li>
                <h5>1 Return to Nature</h5>
              </li>
              <li>
                <h5>1 Safewright Quest</h5>
              </li>
              <li>
                <h5>1 Whispers of the Muse</h5>
              </li>
              <li>
                <h5>1 Iron Myr</h5>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h5>Ejemplo de formato 2:</h5>
            <ul>
              <li>
                <h5>Return to Nature</h5>
              </li>
              <li>
                <h5>Safewright Quest</h5>
              </li>
              <li>
                <h5>Whispers of the Muse</h5>
              </li>
              <li>
                <h5>Iron Myr</h5>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-column h-100">
          <div className="form-group">
            <textarea
              name="card_name"
              className="form-control"
              placeholder="Ingrese un listado de cartas como los ejemplos indicados"
              value={cardName}
              onChange={handleChange}
              rows={10}
              required
            />
          </div>

          <CardFilters
            loadData={handleChangeFilters}
            additionalButtons={
              <>
                <div className="col-lg-1 col-sm-12 mb-2">
                  <button
                    type="button"
                    className="btn butt btn-warning w-100"
                    onClick={e => setShowCards(true)}>
                    <i className="fas fa-id-card"></i>
                  </button>
                </div>
                <div className="col-lg-1 col-sm-12 mb-2">
                  <button
                    type="button"
                    className="btn butt btn-warning w-100"
                    onClick={e => setShowCards(false)}>
                    <i className="fas fa-list-alt"></i>
                  </button>
                </div>
              </>
            }></CardFilters>

          {loading && <Loader></Loader>}
          {!loading && (
            <>
              <TotalAmount cards={cards} />
              {sortedCards.map(cardsByStore => {
                return (
                  <CardPurchaseList
                    store={cardsByStore.store}
                    cards={cardsByStore.cards}
                    showCards={showCards}></CardPurchaseList>
                );
              })}
            </>
          )}
          {!loading && cardsNotFound.length > 0 && (
            <CardsNotFoundList
              cardsNotFound={cardsNotFound}></CardsNotFoundList>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
//test4
export default FindBulk;
