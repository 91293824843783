import React from 'react';
import { useEffect, useState } from 'react';
import CardItem from './CardItem';
import CardName from './CardName';

const CardList = props => {
  return (
    <>
      {props.query && (
        <h2 className="text-white">Resultados para "{props.query}"</h2>
      )}
      <div className="row">
        {props.showCards
          ? props.cards.map(card => <CardItem card={card}></CardItem>)
          : props.cards.map(card => <CardName card={card}></CardName>)}
      </div>
    </>
  );
};

export default CardList;
