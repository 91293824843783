import React, { useState, useEffect } from 'react';
import apiHelpers from '../../../utils/apiHelpers';
import CardPrice from '../CardPrice';
import CardPriceList from '../CardPriceList';
import cardUtils from '../../../utils/cardUtils';
import CardImage from '../CardImage';
import AddToWishlistButton from '../../wishlist/AddToWishlistButton';

const CardItem = props => {
  const [allPrices, setAllPrices] = useState(false);
  const [allPricesButton, setAllPricesButton] = useState(
    'Mostrar más opciones',
  );
  const [allPricesButtonSign, setAllPricesButtonSign] =
    useState('fas fa-plus ml-2');

  return (
    <div className="col-sm-1 col-md-4 col-lg-4 mb-3">
      <div className="card box-shadow text-white">
        <div className="card-header">
          <h4 className="card-title text-grey">{props.card.name}</h4>
          <CardImage card={props.card}></CardImage>
          <AddToWishlistButton card={props.card}></AddToWishlistButton>
        </div>

        {cardUtils.sortPrices(props.card.cardPrices)[0] && (
          <div className="card-body">
            <span className="text-grey">
              <b>Mejor precio:</b>{' '}
            </span>
            <CardPrice
              card={props.card}
              price={cardUtils.sortPrices(props.card.cardPrices)[0]}
              addToCart={true}></CardPrice>
            {cardUtils.sortPrices(props.card.cardPrices)[1] && (
              <button
                type="button"
                className="btn text-white butt mt-3 w-100"
                onClick={e => {
                  setAllPrices(!allPrices);
                  if (allPrices) {
                    setAllPricesButton('Mostrar más opciones');
                    setAllPricesButtonSign('fas fa-plus ml-2 align-middle');
                  } else {
                    setAllPricesButton('Mostrar menos opciones');
                    setAllPricesButtonSign('fas fa-minus ml-2 align-middle');
                  }
                }}>
                {allPricesButton}
                <i class={allPricesButtonSign}></i>
              </button>
            )}
          </div>
        )}
        {cardUtils.sortPrices(props.card.cardPrices)[1] && allPrices && (
          <div className="card-footer">
            <CardPriceList
              card={props.card}
              prices={cardUtils.sortPrices(
                props.card.cardPrices,
              )}></CardPriceList>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardItem;
