import React, { useState, useEffect } from 'react';
import '../Card.css';
import apiHelpers from '../../../utils/apiHelpers';
import Footer from '../../common/Footer';
import { useHistory } from 'react-router-dom';
import CardList from './CardList';
import CardFilters from '../CardFilters';
import Loader from '../../common/Loader';
import { Alert } from 'react-alert';

const FindCard = () => {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [cardName, setCardName] = useState('');
  const [filters, setFilters] = useState({});
  const [showCards, setShowCards] = useState(true);

  useEffect(() => {
    if (cardName.length >= 4) {
      let currCardName = cardName;
      setTimeout(function () {
        if (currCardName == cardName) getCardByName(cardName.trim());
      }, 1000);
    }
  }, [cardName, filters, showCards]);

  const getCardByName = card_name => {
    setLoading(true);
    filters.limit = 24;
    filters.where.name = card_name;

    apiHelpers
      .getCards(filters)
      .then(cards => {
        if (cards.data.length > 0) {
          setCards(
            cards.data /*.filter(
              card =>
                card.cardPrices
                  .map(cardPrice => cardPrice.stock)
                  .reduce((previous, current) => {
                    return previous + current;
                  }) > 0,
            ),*/,
          );
        } else {
          setCards([]);
        }
        setTimeout(function () {
          setLoading(false);
        }, 1000);
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400 || statusCode === 422 || statusCode === 204) {
            alert.error('Error al cargar datos de las cartas');
          } else if (error.request) {
            alert.error('Sin respuesta del Servidor');
          } else {
            alert.error(error.message);
          }
        }
        setLoading(false);
      });
  };

  const handleChange = e => {
    setCardName(e.target.value.toString());
  };

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="container">
        <h1 className="text-position-title text-center">
          <i className="fas fa-search mr-2"></i>
          <u>Ingrese el nombre de una carta</u>
        </h1>

        <div className="d-flex flex-column h-100">
          <div className="form-group">
            <input
              type="text"
              name="card_name"
              className="form-control"
              placeholder='Ingrese el nombre de una carta. Por ejemplo, "Llanowar elves"'
              value={cardName}
              onChange={handleChange}
              required
            />
          </div>

          <CardFilters
            loadData={handleChangeFilters}
            additionalButtons={
              <>
                <div className="col-lg-1 col-sm-12 mb-2">
                  <button
                    type="button"
                    className="btn butt btn-warning w-100"
                    onClick={e => setShowCards(true)}>
                    <i className="fas fa-id-card"></i>
                  </button>
                </div>
                <div className="col-lg-1 col-sm-12 mb-2">
                  <button
                    type="button"
                    className="btn butt btn-warning w-100"
                    onClick={e => setShowCards(false)}>
                    <i className="fas fa-list-alt"></i>
                  </button>
                </div>
              </>
            }></CardFilters>

          {loading && <Loader></Loader>}
          {!loading && (
            <CardList
              query={cardName}
              cards={cards}
              showCards={showCards}></CardList>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FindCard;
