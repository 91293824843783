import React, { useState, useEffect } from 'react';
import ShoppingCart from '../../utils/shoppingCart';

const AddToCartButton = props => {
  return (
    <button
      type="button"
      className="btn butt text-white w-100 mt-2 mb-2"
      onClick={e => {
        ShoppingCart.removeShoppingCartCard(props.card);
        if (props.onRemoveCardFromCart) props.onRemoveCardFromCart();
      }}>
      Eliminar del carrito<i className="fas fa-cart-minus ml-2"></i>
    </button>
  );
};

export default AddToCartButton;
