import shoppingCart from './shoppingCart';

const axios = require('axios');
const config = require('../config/config');
const qs = require('qs');

/* APIs ENDPOINTS */
const SESSION_STORAGE_SESSION = 'session';
const SERVER_URL = config.backend.apiUrl;
const LOGIN_URL = SERVER_URL + '/auth/login';
const CHANGE_PASSWORD_URL = SERVER_URL + '/auth/change-password';
const RESET_URL = SERVER_URL + '/auth/reset';
const GENERATE_PASSWORD_URL = SERVER_URL + '/auth/generate-password';
const USERS_URL = SERVER_URL + '/user';
const USER_ROLES_URL = SERVER_URL + '/userRole';
const FILE_URL = SERVER_URL + '/file';
const FILE_INDEX_URL = SERVER_URL + '/file/{id}';
const FILE_URL_URL = SERVER_URL + '/file/url/{id}';
const CARDS_URL = SERVER_URL + '/card';
const STORES_URL = SERVER_URL + '/store';
const CRAWLER_URL = SERVER_URL + '/crawler/';
const WISHLIST_URL = SERVER_URL + '/wishlist';
const MOXFIELDWISHLIST_URL = SERVER_URL + '/moxfieldWishlist';

function getBackendUrl() {
  return SERVER_URL;
}

// Auth APIs

function login(payload) {
  return axios.post(LOGIN_URL, payload).then(function (response) {
    const token = response.data;
    sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify({ token }));
    shoppingCart.updateStores();
    shoppingCart.updateUSDPrice();
    getCurrentUser().then(function (userResponse) {
      let userData = userResponse.data;
      userData.token = token;
      sessionStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify(userData));
      return userData;
    });
  });
}

function resetPassword(payload) {
  return axios
    .post(RESET_URL, {
      username: payload.email,
    })
    .then(function (response) {
      return response;
    });
}

function changePassword(payload) {
  return axios
    .post(
      CHANGE_PASSWORD_URL,
      {
        username: payload.username,
        password: payload.password,
        newPassword: payload.newPassword,
      },
      buildHeaders(),
    )
    .then(function (response) {
      return response;
    });
}

function generatePassword(payload) {
  return axios
    .post(GENERATE_PASSWORD_URL, payload, buildHeaders())
    .then(function (response) {
      return response;
    });
}

function logout() {
  sessionStorage.clear(SESSION_STORAGE_SESSION);
}

function isAuthenticated() {
  return sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null;
}

function isAdmin() {
  return (
    sessionStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).role == 'ADMIN'
  );
}

function buildHeaders() {
  if (sessionStorage.getItem(SESSION_STORAGE_SESSION)) {
    return {
      headers: {
        Authorization:
          'Bearer ' +
          JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SESSION)).token,
      },
    };
  } else {
    return null;
  }
}

// User APIs

function getUsers(parameters = []) {
  return axios.get(
    `${USERS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getUsersCount(parameters = []) {
  return axios.get(
    `${USERS_URL + '/count'}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getUser(id) {
  return axios.get(USERS_URL + '/' + id, buildHeaders());
}

function postUser(body) {
  return axios.post(USERS_URL + '/', body, buildHeaders());
}

function patchUser(id, body) {
  return axios.patch(USERS_URL + '/' + id, body, buildHeaders());
}

function deleteUser(id) {
  return axios.delete(USERS_URL + '/' + id, buildHeaders());
}

function getCurrentUser() {
  return axios.get(USERS_URL + '/me', buildHeaders());
}

function getUserRoles(parameters = []) {
  return axios.get(
    `${USER_ROLES_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function updateMoxfieldURL(url) {
  return axios.patch(
    USERS_URL + '/moxfieldWishlist',
    { moxfieldWishlist: url },
    buildHeaders(),
  );
}

// File APIs

function getFiles(parameters = []) {
  return axios.get(
    `${FILE_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getFilesCount() {
  return axios.get(FILE_URL + '/count', buildHeaders());
}

function getFile(id) {
  return axios.get(FILE_INDEX_URL.replace('{id}', id), buildHeaders());
}

function getFileURL(id) {
  return axios.get(FILE_URL_URL.replace('{id}', id), buildHeaders());
}

function postFile(file) {
  const data = new FormData();
  data.append('file', document.file);
  return axios.post(FILE_URL, data, buildHeaders());
}

function patchFile(id, payload) {
  return axios.patch(
    FILE_INDEX_URL.replace('{id}', id),
    payload,
    buildHeaders(),
  );
}

function deleteFile(id) {
  return axios.delete(FILE_INDEX_URL.replace('{id}', id), buildHeaders());
}

// Cards APIs

function getCards(parameters = []) {
  return axios.get(
    `${CARDS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getCardsBulk(parameters = []) {
  return axios.get(
    `${CARDS_URL + '/bulk'}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getCardsCount(parameters = []) {
  return axios.get(
    `${CARDS_URL + '/count'}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getCard(id) {
  return axios.get(CARDS_URL + '/' + id, buildHeaders());
}

function getCardByName(name) {
  return axios.get(
    encodeURI('https://api.scryfall.com/cards/named?fuzzy=' + name),
  );
}

async function getCardImageByName(name) {
  return await axios.get(
    encodeURI('https://api.scryfall.com/cards/named?fuzzy=' + name),
  );
}

// Stores APIs

function getStores() {
  return axios.get(STORES_URL, buildHeaders());
}

// Crawler APIs

function crawl(store) {
  return axios.get(CRAWLER_URL + store, buildHeaders());
}

// Wishlist APIs

function getWishlist(parameters = []) {
  return axios.get(
    `${WISHLIST_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function addCardToWishlist(id) {
  return axios.get(WISHLIST_URL + '/add/' + id, buildHeaders());
}

function removeCardFromWishlist(id) {
  return axios.get(WISHLIST_URL + '/remove/' + id, buildHeaders());
}

function updateWishlist(parameters = []) {
  return axios.get(
    `${WISHLIST_URL + '/update'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function updateMoxfieldWishlist() {
  return axios.get(WISHLIST_URL + '/moxfield', buildHeaders());
}

function getUSDPrice() {
  return axios.get('https://api.bluelytics.com.ar/v2/latest');
}

export default {
  SERVER_URL,
  getBackendUrl,
  logout,
  login,
  resetPassword,
  changePassword,
  generatePassword,
  isAuthenticated,
  isAdmin,
  getUsers,
  getUsersCount,
  getUser,
  postUser,
  patchUser,
  deleteUser,
  getCurrentUser,
  getUserRoles,
  updateMoxfieldURL,
  getFiles,
  getFilesCount,
  getFile,
  getFileURL,
  postFile,
  patchFile,
  deleteFile,
  getCards,
  getCardsBulk,
  getCardsCount,
  getCard,
  getCardByName,
  getCardImageByName,
  crawl,
  getStores,
  getWishlist,
  addCardToWishlist,
  removeCardFromWishlist,
  updateWishlist,
  updateMoxfieldWishlist,
  getUSDPrice,
};
