import React from 'react';

export default () => {
  return (
    <></>
    /*<footer className="footer fixed-bottom ">
      <div className="container">
        <div className="text-center">
          <span>
            Made with <span className="red">♥</span> by VenTuring
          </span>
        </div>
      </div>
    </footer>*/
  );
};
