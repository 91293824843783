import React, { useState, useEffect } from 'react';

const CardsNotFoundList = props => {
  const [showList, setShowList] = useState(true);

  return (
    <>
      {props.cardsNotFound.length && (
        <div className="store-div mb-3" onClick={e => setShowList(!showList)}>
          <span className="store-title text-grey ml-2">
            Cartas no encontradas
          </span>
          {showList ? (
            <i className="pull-right fas fa-angle-double-up mt-2 mr-2"></i>
          ) : (
            <i className=" pull-right fas fa-angle-double-down mt-2 mr-2"></i>
          )}
        </div>
      )}
      {showList && (
        <ul>
          {props.cardsNotFound.map(cardNotFound => (
            <li className="text-white">
              <h5>{cardNotFound}</h5>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default CardsNotFoundList;
