import shoppingCart from './shoppingCart';

export default {
  calculatePrice: price => {
    if (price.currency == 'ARS') return price.price + ' ' + price.currency;
    if (price.currency == 'USD')
      return (
        price.price +
        ' ' +
        price.currency +
        ' (' +
        price.price *
          shoppingCart.getStores().find(store => store.store == price.store)
            .exchangeRate +
        ' ARS)'
      );
  },

  comparePrice: price => {
    try {
      if (!price.standardPrice) return 'hidden';
      let priceInUSD;
      if (price.currency == 'ARS')
        priceInUSD = price.price / shoppingCart.getUSDPrice();
      if (price.currency == 'USD') priceInUSD = price.price;
      if (priceInUSD) {
        // If the price is over x1.5 standard price, mark it as expensive
        if (priceInUSD > price.standardPrice * 1.5) return 'red';
        // If the price is under x0.5 standard price, mark it as cheap
        if (priceInUSD < price.standardPrice * 0.5) return 'green';
        // Otherwise, mark it as regular
        return 'yellow';
      }
      return 'hidden';
    } catch (e) {
      return 'hidden';
    }
  },

  sortPrices: priceList => {
    if (!priceList) return [];
    return priceList
      .filter(
        price =>
          // Filter cards with no stock
          price.stock > 0,
      )
      .sort(function compare(a, b) {
        // Sort by lower price
        if (
          a.price *
            shoppingCart.getStores().find(store => store.store == a.store)
              .exchangeRate <
          b.price *
            shoppingCart.getStores().find(store => store.store == b.store)
              .exchangeRate
        ) {
          return -1;
        }
        if (
          a.price *
            shoppingCart.getStores().find(store => store.store == a.store)
              .exchangeRate >
          b.price *
            shoppingCart.getStores().find(store => store.store == b.store)
              .exchangeRate
        ) {
          return 1;
        }
        return 0;
      });
  },

  groupCardsByStore: cards => {
    let cardsSortedByStore = [];
    cards.map(card => {
      const bestPrice = card.cardPrices
        .filter(cardPrice => cardPrice.stock > 0)
        .sort(function compare(a, b) {
          // Sort by lower price
          if (
            a.price *
              shoppingCart.getStores().find(store => store.store == a.store)
                .exchangeRate <
            b.price *
              shoppingCart.getStores().find(store => store.store == b.store)
                .exchangeRate
          ) {
            return -1;
          }
          if (
            a.price *
              shoppingCart.getStores().find(store => store.store == a.store)
                .exchangeRate >
            b.price *
              shoppingCart.getStores().find(store => store.store == b.store)
                .exchangeRate
          ) {
            return 1;
          }
          return 0;
        })[0];
      if (bestPrice) {
        if (!cardsSortedByStore.find(elem => elem.store == bestPrice.store))
          cardsSortedByStore.push({ store: bestPrice.store, cards: [] });
        cardsSortedByStore
          .find(elem => elem.store == bestPrice.store)
          .cards.push(card);
      }
    });
    return cardsSortedByStore;
  },
};
