import React, { useState, useEffect } from 'react';
import ShoppingCart from '../../utils/shoppingCart';

const AddToCartButton = props => {
  const [disabled, setDisabled] = useState(false);

  return (
    <button
      type="button"
      className="btn butt text-white w-100 mt-2 mb-2"
      onClick={e => {
        setDisabled(true);
        ShoppingCart.addShoppingCartCard(props.card, props.price);
      }}
      disabled={disabled}>
      Agregar al carrito<i className="fas fa-cart-plus ml-2"></i>
    </button>
  );
};

export default AddToCartButton;
