import React, { useState, useEffect } from 'react';
import apiHelpers from '../../utils/apiHelpers';

const AddToWishlistButton = props => {
  const [disabled, setDisabled] = useState(false);

  return (
    <button
      type="button"
      className="btn butt text-white w-100 mt-2 mb-2"
      onClick={e => {
        setDisabled(true);
        apiHelpers.addCardToWishlist(props.card.id);
      }}
      disabled={disabled}>
      Agregar a wishlist<i className="fas fa-heart ml-2"></i>
    </button>
  );
};

export default AddToWishlistButton;
