import React, { useState, useEffect } from 'react';
import cardUtils from '../../utils/cardUtils';
import shoppingCart from '../../utils/shoppingCart';
import formatter from '../../utils/formatter';

const TotalAmount = props => {
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setTotalAmount(
      props.cards
        .map(card => cardUtils.sortPrices(card.cardPrices)[0])
        .reduce(
          (prev, curr) =>
            curr
              ? prev +
                curr.price *
                  shoppingCart
                    .getStores()
                    .find(store => store.store == curr.store).exchangeRate
              : prev + 0,
          0,
        ),
    );
  }, [props.cards]);

  return (
    <>
      {props.cards && (
        <div className="store-div mb-3">
          <span className="store-title text-grey align-middle ml-3 mr-3">
            Monto total: {formatter.formatNumberDecimals(totalAmount)} ARS
          </span>
        </div>
      )}
    </>
  );
};

export default TotalAmount;
