import React, { useState, useEffect } from 'react';
import shoppingCart from '../../utils/shoppingCart';

const SendEmailButton = props => {
  const [disabled, setDisabled] = useState(false);

  const sendMail = () => {
    var subject = `Pedido ${props.store}`;
    var body = `Hola ${
      props.store
    }, quería hacer un pedido de las siguientes cartas:
${props.cards
  .map(card => {
    return (
      card.name +
      ' - ' +
      card.cardPrices[0].edition +
      ' - ' +
      card.cardPrices[0].condition +
      ' - ' +
      card.cardPrices[0].language +
      ': $ ' +
      card.cardPrices[0].price
    );
  })
  .join('\n')}
Total: ${props.totalAmount} ${props.cards[0].cardPrices[0].currency}`;
    var uri = `mailto:${shoppingCart.getStore(props.store).email}?subject=`;
    uri += encodeURIComponent(subject);
    uri += '&body=';
    uri += encodeURIComponent(body);
    window.open(uri);
  };

  return (
    <button
      type="button"
      className="btn butt text-white w-20 mt-2 mb-2"
      onClick={e => sendMail()}
      disabled={disabled}>
      Enviar email<i className="fas fa-cart-minus ml-2"></i>
    </button>
  );
};

export default SendEmailButton;
