import React from 'react';
import cardUtils from '../../utils/cardUtils';
import AddToCartButton from '../shoppingCart/AddToCartButton';
import RemoveFromCartButton from '../shoppingCart/RemoveFromCartButton';

const CardPrice = props => {
  return (
    <li className="list-group-item text-grey">
      <span className="text-grey">
        <b>Tienda:</b> {props.price.store}
      </span>
      <br></br>
      <span className="text-grey">
        <b>Precio:</b> {cardUtils.calculatePrice(props.price)}{' '}
        <i class={`fas fa-circle ${cardUtils.comparePrice(props.price)}`}></i>
      </span>
      <br></br>
      <span className="text-grey">
        <b>Stock:</b> {props.price.stock}
      </span>
      <br></br>
      <span className="text-grey">
        <b>Condición:</b> {props.price.condition}
      </span>
      <br></br>
      <span className="text-grey">
        <b>Edición:</b> {props.price.edition}
      </span>
      <br></br>
      <span className="text-grey">
        <b>Idioma:</b> {props.price.language}
      </span>
      <br></br>
      {props.price.standardPrice != undefined &&
        props.price.standardPrice != 0 && (
          <>
            <span className="text-grey">
              <b>Precio en TCGPlayer:</b> {props.price.standardPrice} USD
            </span>
            <br></br>
          </>
        )}
      <a href={props.price.storeUrl} target="_blank">
        <button type="button" className="btn butt text-white w-100 mt-2 mb-2">
          Ver en tienda<i className="fas fa-shopping-basket ml-2"></i>
        </button>
      </a>
      <br></br>
      {props.addToCart && (
        <AddToCartButton
          card={props.card}
          price={props.price}></AddToCartButton>
      )}
      {props.removeFromCart && (
        <RemoveFromCartButton
          card={props.card}
          price={props.price}
          onRemoveCardFromCart={props.removeFromCart}></RemoveFromCartButton>
      )}
    </li>
  );
};

export default CardPrice;
