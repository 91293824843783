import React, { useState, useEffect } from 'react';
import '../card/Card.css';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import CardsNotFoundList from '../card/CardsNotFoundList';
import CardWishlistItem from './CardWishlistItem';
import cardUtils from '../../utils/cardUtils';
import CardWishlistList from './CardwishlistList';
import TotalAmount from '../common/TotalAmount';
import CardFilters from '../card/CardFilters';
import { Alert } from 'react-alert';

const Wishlist = () => {
  const [loading, setLoading] = useState(true);
  const [editable, setEditable] = useState(true);
  const [cards, setCards] = useState([]);
  const [sortedCards, setSortedCards] = useState([]);
  const [cardsNotFound, setCardsNotFound] = useState([]);
  const [cardName, setCardName] = useState('');
  const [filters, setFilters] = useState({});
  const [showSorted, setShowSorted] = useState(false);
  const [showCards, setShowCards] = useState(true);

  useEffect(() => {
    apiHelper.getCurrentUser().then(currentUser => {
      if (currentUser.data.moxfieldWishlist) {
        setEditable(false);
      }
      loadWishlist();
    });
  }, []);

  useEffect(() => {
    loadWishlist();
  }, [filters]);

  const loadWishlist = () => {
    apiHelper.getWishlist(filters).then(wishlist => {
      setCards(wishlist.data.map(wishlist => wishlist.card));
      setSortedCards(
        cardUtils.groupCardsByStore(
          wishlist.data
            .map(wishlist => wishlist.card)
            .filter(card => card.cardPrices.length > 0),
        ),
      );
      setCardName(wishlist.data.map(wishlist => wishlist.card.name).join('\n'));
      setLoading(false);
    });
  };
  const handleChange = e => {
    setCardName(e.target.value.toString());
  };

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const saveWishlist = e => {
    setLoading(true);
    if (editable) {
      apiHelper
        .updateWishlist({
          where: {
            name: cardName,
          },
        })
        .then(wishlist => {
          setCards(wishlist.data.wishlist.map(wishlist => wishlist.card));
          setSortedCards(
            cardUtils.groupCardsByStore(
              wishlist.data.wishlist
                .map(wishlist => wishlist.card)
                .filter(card => card.cardPrices.length > 0),
            ),
          );
          setCardsNotFound(wishlist.data.cardsNotFound);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            const statusCode = error.response.status;
            if (
              statusCode === 400 ||
              statusCode === 422 ||
              statusCode === 204
            ) {
              alert.error('Error al cargar datos de las cartas');
            } else if (error.request) {
              alert.error('Sin respuesta del Servidor');
            } else {
              alert.error(error.message);
            }
          }
          setLoading(false);
        });
    } else {
      apiHelper
        .updateMoxfieldWishlist()
        .then(wishlist => {
          setCards(wishlist.data.wishlist.map(wishlist => wishlist.card));
          setSortedCards(
            cardUtils.groupCardsByStore(
              wishlist.data.wishlist
                .map(wishlist => wishlist.card)
                .filter(card => card.cardPrices.length > 0),
            ),
          );
          setCardsNotFound(wishlist.data.cardsNotFound);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            const statusCode = error.response.status;
            if (
              statusCode === 400 ||
              statusCode === 422 ||
              statusCode === 204
            ) {
              alert.error('Error al cargar datos de las cartas');
            } else if (error.request) {
              alert.error('Sin respuesta del Servidor');
            } else {
              alert.error(error.message);
            }
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="container">
        <h1 className="text-position-title text-center">
          <i className="fas fa-search mr-2"></i>
          <u>Ingrese un listado de cartas para agregar a su wishlist</u>
        </h1>

        <h4 className="text-white">
          El formato debe ser "[cantidad de unidades] [nombre de carta]"
          (Formato MTGO) o bien "[nombre de carta]"
        </h4>
        <div className="row text-white">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h5>Ejemplo de formato 1:</h5>
            <ul>
              <li>
                <h5>1 Return to Nature</h5>
              </li>
              <li>
                <h5>1 Safewright Quest</h5>
              </li>
              <li>
                <h5>1 Whispers of the Muse</h5>
              </li>
              <li>
                <h5>1 Iron Myr</h5>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h5>Ejemplo de formato 2:</h5>
            <ul>
              <li>
                <h5>Return to Nature</h5>
              </li>
              <li>
                <h5>Safewright Quest</h5>
              </li>
              <li>
                <h5>Whispers of the Muse</h5>
              </li>
              <li>
                <h5>Iron Myr</h5>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-flex flex-column h-100">
          <div className="form-group">
            <textarea
              name="card_name"
              className="form-control"
              placeholder="Ingrese un listado de cartas como los ejemplos indicados"
              value={cardName}
              onChange={handleChange}
              rows={10}
              required
              disabled={!editable}
            />
          </div>

          {!loading && cardsNotFound.length > 0 && (
            <CardsNotFoundList
              cardsNotFound={cardsNotFound}></CardsNotFoundList>
          )}

          <CardFilters loadData={handleChangeFilters}></CardFilters>

          <div className="row mb-3">
            <div className="col-lg-3 col-sm-12 mb-2">
              <button
                type="button"
                className="btn butt text-white w-100"
                onClick={e => {
                  setShowSorted(false);
                  setShowCards(true);
                }}
                disabled={loading}>
                Ver por carta<i className="fas fa-id-card ml-2"></i>
              </button>
            </div>
            <div className="col-lg-3 col-sm-12 mb-2">
              <button
                type="button"
                className="btn butt text-white w-100"
                onClick={e => {
                  setShowSorted(true);
                  setShowCards(true);
                }}
                disabled={loading}>
                Ver por tienda (cartas) <i className="fas fa-store ml-2"></i>
              </button>
            </div>
            <div className="col-lg-3 col-sm-12 mb-2">
              <button
                type="button"
                className="btn butt text-white w-100"
                onClick={e => {
                  setShowSorted(true);
                  setShowCards(false);
                }}
                disabled={loading}>
                Ver por tienda (lista)<i className="fas fa-list-alt ml-2"></i>
              </button>
            </div>
            <div className="col-lg-3 col-sm-12 mb-2">
              <button
                type="button"
                className="btn butt text-white w-100"
                onClick={e => saveWishlist()}
                disabled={loading}>
                {editable
                  ? 'Actualizar wishlist'
                  : 'Actualizar wishlist desde Moxfield'}
                <i
                  className={
                    editable ? 'fas fa-save ml-2' : 'fas fa-download ml-2'
                  }></i>
              </button>
            </div>
          </div>

          {loading && <Loader></Loader>}
          {!loading && !showSorted && (
            <>
              <TotalAmount cards={cards} />
              <div className="row">
                {cards.map(card => {
                  return <CardWishlistItem card={card}></CardWishlistItem>;
                })}
              </div>
            </>
          )}
          {!loading && showSorted && (
            <>
              <TotalAmount cards={cards} />
              {sortedCards.map(cardsByStore => {
                return (
                  <CardWishlistList
                    store={cardsByStore.store}
                    cards={cardsByStore.cards}
                    showCards={showCards}></CardWishlistList>
                );
              })}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Wishlist;
