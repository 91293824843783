import React, { useState, useEffect } from 'react';
import apiHelpers from '../../utils/apiHelpers';

const RemoveFromWishlistButton = props => {
  const [disabled, setDisabled] = useState(false);

  return (
    <button
      type="button"
      className="btn butt text-white w-100 mt-2 mb-2"
      onClick={e => {
        setDisabled(true);
        apiHelpers.removeCardFromWishlist(props.card.id);
      }}
      disabled={disabled}>
      Quitar de wishlist<i className="fas fa-trash ml-2"></i>
    </button>
  );
};

export default RemoveFromWishlistButton;
