import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Login.css';
import apiHelper from '../../utils/apiHelpers';
import ReCAPTCHA from 'react-google-recaptcha';
const config = require('../../config/config');

const Login = props => {
  const [state, setState] = useState({
    username: '',
    password: '',
    errorMessage: null,
    key: '',
  });

  const handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onCaptchaComplete = value => {
    setState(prevState => ({
      ...prevState,
      ['key']: value,
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      await apiHelper.login({
        username: state.username,
        password: state.password,
        key: state.key,
      });
      props.history.push('/findcard');
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 401) {
        errorMessage = 'Email o contraseña invalidas';
      } else if (statusCode === 403) {
        errorMessage = 'Captcha invalido';
      } else {
        errorMessage = error.message;
      }

      setState(prevState => ({
        ...prevState,
        ['errorMessage']: errorMessage,
      }));
    }
  };

  let alert;
  if (state.errorMessage) {
    alert = <div className="alert alert-warning">{state.errorMessage}</div>;
  }

  return (
    <div className="container">
      <div className="wrapper fadeInDown">
        <form className="form-signin" onSubmit={handleSubmit.bind(this)}>
          <div className="fadeIn first">
            <div>
              <img
                src={process.env.PUBLIC_URL + '/img/isologo.png'}
                width="170px"
                alt="logo"
              />
            </div>
            <h4 className="h4 mb-3 font-weight-normal">Ingrese sus datos</h4>
            <div className="form-group mb-3">
              <label htmlFor="inputEmail" className="sr-only">
                Usuario
              </label>
              <input
                type="text"
                name="username"
                className="form-control fadeIn second"
                placeholder="Usuario"
                value={state.username}
                onChange={handleChange}
                required
                autoFocus
              />
            </div>

            <div className="form-group">
              <label htmlFor="inputPassword" className="sr-only">
                Contraseña
              </label>
              <input
                type="password"
                name="password"
                className="form-control fadeIn third"
                placeholder="Contraseña"
                value={state.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 fadeIn fourth">
              <ReCAPTCHA
                sitekey={config.captcha}
                onChange={onCaptchaComplete}
              />
            </div>
            {alert}
            <button
              className="btn  butt btn-lg-4 btn-primary btn-block fadeIn fourth"
              formAction="index.html"
              type="submit">
              Iniciar sesión <i className="fas fa-sign-in-alt" />
            </button>
            <hr />
            <Link to="/recover_password">Olvide mi Contraseña</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
