import React, { useState } from 'react';
import Login from '../login/Login';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import PrivateRoute from '../../utils/PrivateRoute';
import UserDetail from '../user/UserDetail';
import NewAside from '../common/SideMenu';
import Footer from '../common/Footer';
import RecoverPassword from '../login/RecoverPassword';
import FindCard from '../card/findCard/FindCard';
import FindBulk from '../card/findBulk/FindBulk';
import Wishlist from '../wishlist/Wishlist';
import Profile from '../profile/Profile';
import ShoppingCart from '../../components/shoppingCart/ShoppingCart';

const history = createBrowserHistory();

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route name="login" path={`/login`} component={Login} />
          <Route name="default" exact path={`/`} component={Login} />
          <Route
            name="recover_password"
            path={`/recover_password`}
            component={RecoverPassword}
          />
          <div>
            <NewAside />
            <PrivateRoute
              name="profile"
              exact
              path={`/profile`}
              component={Profile}
            />
            <PrivateRoute
              name="findcard"
              exact
              path={`/findcard`}
              component={FindCard}
            />
            <PrivateRoute
              name="findbulk"
              exact
              path={`/findbulk`}
              component={FindBulk}
            />
            <PrivateRoute
              name="wishlist"
              exact
              path={`/wishlist`}
              component={Wishlist}
            />
            <PrivateRoute
              name="shoppingcart"
              exact
              path={`/shoppingcart`}
              component={ShoppingCart}
            />
            <Footer />
          </div>
        </Switch>
      </Router>
    </>
  );
}

export default App;
