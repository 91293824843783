import React from 'react';
import { useEffect, useState } from 'react';
import SelectCardCondition from '../common/selects/SelectCardCondition';
import SelectCardLanguage from '../common/selects/SelectCardLanguage';

const CardFilters = props => {
  const [state, setState] = useState({
    condition: null,
    language: null,
  });

  useEffect(() => {
    props.loadData(getFiltersObject());
  }, [state]);

  const getFiltersObject = () => {
    return {
      where: {
        conditionRank: state.condition,
        language: state.language,
      },
    };
  };

  const onFiltersChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onFiltersRefresh = () => {
    setState({
      state: null,
    });
  };

  return (
    <div className="row select-font transactions-filters mb-3">
      <div className="col-lg-4 col-sm-12 mb-2">
        <SelectCardCondition
          value={state.condition}
          name={'condition'}
          placeholder={'Condición mínima'}
          onChange={onFiltersChange.bind(this)}
        />
      </div>
      <div className="col-lg-4 col-sm-12 mb-2">
        <SelectCardLanguage
          value={state.language}
          name={'language'}
          placeholder={'Idioma'}
          onChange={onFiltersChange.bind(this)}
        />
      </div>
      <div
        className={
          props.additionalButtons
            ? 'col-lg-2 col-sm-12 mb-2'
            : 'col-lg-4 col-sm-12 mb-2'
        }>
        <button
          type="button"
          className="btn butt btn-warning w-100"
          onClick={onFiltersRefresh.bind(this)}>
          Reiniciar filtros <i className="fas fa-sync"></i>
        </button>
      </div>
      {props.additionalButtons}
    </div>
  );
};

export default CardFilters;
