import React, { useState, useEffect } from 'react';
import CardName from '../card/findCard/CardName';
import cardUtils from '../../utils/cardUtils';
import ShoppingCartItem from './ShoppingCartItem';
import SendEmailButton from './SendEmailButton';
import formatter from '../../utils/formatter';

const ShoppingCartList = props => {
  const [showList, setShowList] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setTotalAmount(
      props.cards
        .map(card => cardUtils.sortPrices(card.cardPrices)[0].price)
        .reduce((prev, curr) => prev + curr, 0),
    );
  }, [props.cards]);

  return (
    <>
      {props.store && (
        <div className="store-div mb-3">
          <span className="store-title text-grey align-middle ml-3 mr-3">
            Cartas a adquirir en {props.store} (Total:{' '}
            {formatter.formatNumberDecimals(totalAmount)}{' '}
            {cardUtils.sortPrices(props.cards[0].cardPrices)[0].currency})
          </span>
          <span onClick={e => setShowList(!showList)}>
            {showList ? (
              <i className="pull-right fas fa-angle-double-up mt-3 mr-3"></i>
            ) : (
              <i className=" pull-right fas fa-angle-double-down mt-3 mr-3"></i>
            )}
          </span>
          <SendEmailButton
            store={props.store}
            cards={props.cards}
            totalAmount={totalAmount}></SendEmailButton>
        </div>
      )}
      {showList && (
        <div className="row overlay">
          {props.showCards
            ? props.cards.map(card => (
                <ShoppingCartItem
                  card={card}
                  onRemoveCardFromCart={
                    props.onRemoveCardFromCart
                  }></ShoppingCartItem>
              ))
            : props.cards.map(card => <CardName card={card}></CardName>)}
        </div>
      )}
    </>
  );
};

export default ShoppingCartList;
