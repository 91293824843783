import React, { useState, useEffect } from 'react';
import apiHelpers from '../../utils/apiHelpers';

const CardImage = props => {
  const [cardImage, setCardImage] = useState('');
  const [cardURL, setCardURL] = useState('');

  useEffect(() => {
    if (props.card.imageUrl) {
      setCardImage(props.card.imageUrl);
      setCardURL(props.card.cardUrl);
      return;
    }
    let currCardName = props.card.name;
    setTimeout(function () {
      if (!props.card.imageUrl && currCardName == props.card.name)
        apiHelpers.getCardImageByName(props.card.name).then(imageResult => {
          if (
            imageResult.data &&
            imageResult.data.image_uris &&
            imageResult.data.image_uris.normal
          )
            setCardImage(imageResult.data.image_uris.normal);
          if (imageResult.data && imageResult.data.scryfall_uri)
            setCardURL(imageResult.data.scryfall_uri);
        });
    }, 1000);
  }, [props.card]);

  return (
    <a href={cardURL} target="_blank">
      <img className="card-image" src={cardImage ? cardImage : ''}></img>
    </a>
  );
};

export default CardImage;
