import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import apiHelpers from '../utils/apiHelpers';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      apiHelpers.isAuthenticated() === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default PrivateRoute;
