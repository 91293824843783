import React, { useState, useEffect } from 'react';
import '../card/Card.css';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import cardUtils from '../../utils/cardUtils';
import shoppingCart from '../../utils/shoppingCart';
import EmptyCartButton from './EmptyCartButton';
import ShoppingCartList from './ShooppingCartList';
import TotalAmount from '../common/TotalAmount';

const ShoppingCart = () => {
  const [loading, setLoading] = useState(false);
  const [sortedCards, setSortedCards] = useState([]);
  const [showCards, setShowCards] = useState(true);

  useEffect(() => {
    setLoading(true);
    setSortedCards(cardUtils.groupCardsByStore(shoppingCart.getShoppingCart()));
    setLoading(false);
  }, []);

  const onRemoveCardFromCart = () => {
    setLoading(true);
    setSortedCards(cardUtils.groupCardsByStore(shoppingCart.getShoppingCart()));
    setLoading(false);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="container">
        <h1 className="text-position-title text-center">
          <i className="fas fa-shopping-cart mr-2"></i>
          <u>Carrito de compras</u>
        </h1>

        <div className="d-flex flex-column h-100">
          <div className="row mb-3">
            <div className="col-lg-4 col-sm-12 mb-2">
              <EmptyCartButton
                onEmptyCart={() => setSortedCards([])}></EmptyCartButton>
            </div>
            <div className="col-lg-1 col-sm-12 mb-2">
              <button
                type="button"
                className="btn butt btn-warning w-100"
                onClick={e => setShowCards(true)}>
                <i className="fas fa-id-card"></i>
              </button>
            </div>
            <div className="col-lg-1 col-sm-12 mb-2">
              <button
                type="button"
                className="btn butt btn-warning w-100"
                onClick={e => setShowCards(false)}>
                <i className="fas fa-list-alt"></i>
              </button>
            </div>
          </div>

          {loading && <Loader></Loader>}
          {!loading && (
            <>
              <TotalAmount cards={shoppingCart.getShoppingCart()} />
              {sortedCards.map(cardsByStore => {
                return (
                  <ShoppingCartList
                    store={cardsByStore.store}
                    cards={cardsByStore.cards}
                    showCards={showCards}
                    onRemoveCardFromCart={
                      onRemoveCardFromCart
                    }></ShoppingCartList>
                );
              })}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ShoppingCart;
