import React, { useState, useEffect } from 'react';
import cardUtils from '../../utils/cardUtils';
import shoppingCart from '../../utils/shoppingCart';
import CardPrice from './CardPrice';

const CardPriceList = props => {
  const [stores, setStores] = useState([]);

  useEffect(() => {
    setStores(shoppingCart.getStores());
  }, []);

  return (
    <>
      {stores.length && (
        <ul className="list-group list-group-flush">
          {cardUtils.sortPrices(props.prices).map(price => (
            <CardPrice
              card={props.card}
              price={price}
              addToCart={true}></CardPrice>
          ))}
        </ul>
      )}
    </>
  );
};

export default CardPriceList;
