import React, { useState, useEffect } from 'react';
import cardUtils from '../../utils/cardUtils';
import AddToCartButton from '../shoppingCart/AddToCartButton';
import RemoveFromWishlistButton from './RemoveFromWishlistButton';

const CardWishlistName = props => {
  return (
    <div className="col-12 mb-2">
      <div className="row store-div box-shadow text-white m-0">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <h4 className="card-title card-name text-grey">
            {props.card.name}{' '}
            {props.card.cardPrices && props.card.cardPrices.length
              ? '(' +
                cardUtils.sortPrices(props.card.cardPrices)[0].price +
                ' ' +
                cardUtils.sortPrices(props.card.cardPrices)[0].currency +
                ')'
              : '(Sin stock)'}
          </h4>
        </div>
        <div className="col-sm-12 col-md-2 col-lg-2">
          <RemoveFromWishlistButton
            card={props.card}></RemoveFromWishlistButton>
        </div>
        {props.card.cardPrices && props.card.cardPrices.length && (
          <>
            <div className="col-sm-12 col-md-2 col-lg-2">
              <AddToCartButton
                card={props.card}
                price={
                  cardUtils.sortPrices(props.card.cardPrices)[0]
                }></AddToCartButton>
            </div>
            <div className="col-sm-12 col-md-2 col-lg-2">
              <a
                href={cardUtils.sortPrices(props.card.cardPrices)[0].storeUrl}
                target="_blank">
                <button
                  type="button"
                  className="btn butt text-white w-100 mt-2 mb-2">
                  Ver en tienda<i className="fas fa-shopping-basket ml-2"></i>
                </button>
              </a>
            </div>
          </>
        )}
        <div className="col-sm-12 col-md-2 col-lg-2">
          <a href={props.card.cardUrl} target="_blank">
            <button
              type="button"
              className="btn butt text-white w-100 mt-2 mb-2">
              Ver en Scryfall<i className="fas fa-database ml-2"></i>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CardWishlistName;
