import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import Footer from '../common/Footer';
import formatter from '../../utils/formatter';
import Tooltip from '../common/Tooltip';
import SelectUserRole from '../common/selects/SelectUserRole';
import StickyHeader from '../common/StickyHeader';

const Profile = props => {
  const [user, setUser] = useState({
    username: '',
    email: '',
    password: '',
    moxfieldWishlist: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    apiHelper
      .getCurrentUser()
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleChange = event => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    value =
      target.type === 'react-datetime'
        ? formatter.formatDateForAPI(target.value)
        : target.value;
    const name = target.name;
    const newUser = Object.assign({}, user);
    newUser[name] = value;
    setUser(newUser);
  };

  const submitUser = async () => {
    try {
      setLoading(true);
      //If requests already exists, update it. If not, create it
      await apiHelper.updateMoxfieldURL(user.moxfieldWishlist);

      setLoading(false);
      props.history.push('/wishlist');
    } catch (error) {
      setLoading(false);
      const statusCode = error.response.status;
      let errorMessage;
      if (statusCode === 422) {
        errorMessage = 'Datos invalidos o incompletos';
      } else {
        errorMessage = error.response.data.error
          ? error.response.data.error.message
          : error.response.data;
      }
      setErrorMessage(errorMessage);
    }
  };

  let alert;

  if (errorMessage) {
    alert = <div className="alert alert-warning">{errorMessage}</div>;
  }

  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader titulo={user.username} icon={'fas fa-user icon-vtasks'} />
      <div className="container">
        <div className="row mb-1 mt-3 justify-content-end">
          <button
            className="btn butt text-white mr-3"
            onClick={e => submitUser()}>
            Guardar <i className="fas fa-save" />
          </button>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-12 col-lg-3">
            <div className="form-group">
              <label htmlFor="username">Usuario</label>
              <span
                data-tip
                data-for={'Tooltip-username'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-username'}
                tooltipText={
                  'Nombre de usuario. Junto con la contraseña, se utilizará para iniciar sesión.'
                }
              />
              <input
                type="text"
                name="username"
                className="form-control"
                placeholder="No definido"
                value={user.username}
                disabled
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="form-group">
              <label htmlFor="password">Contraseña</label>
              <span
                data-tip
                data-for={'Tooltip-password'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-password'}
                tooltipText={
                  'Contraseña del usuario. Junto con el nombre de usuario, se utilizará para iniciar sesión.'
                }
              />
              <input
                type="text"
                name="password"
                className="form-control"
                placeholder="No definido"
                value={user.password}
                disabled
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <span
                data-tip
                data-for={'Tooltip-email'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-email'}
                tooltipText={
                  'Email del usuario. Se utilizará para recibir las notificaciones del sistema.'
                }
              />
              <input
                type="text"
                name="email"
                className="form-control"
                placeholder="No definido"
                value={user.email}
                disabled
              />
            </div>
          </div>
          <div className="col-md-12 col-lg-3">
            <div className="form-group">
              <label htmlFor="moxfieldWishlist">Moxfield Wishlist URL</label>
              <span
                data-tip
                data-for={'Tooltip-moxfieldWishlist'}
                className="ml-1 fa fa-info-circle"></span>
              <Tooltip
                id={'Tooltip-moxfieldWishlist'}
                tooltipText={
                  'Wishlist de Moxfield del usuario. Se utilizará para actualizar la Wishlist.'
                }
              />
              <input
                type="text"
                name="moxfieldWishlist"
                className="form-control"
                placeholder="No definido"
                value={user.moxfieldWishlist}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        </div>
        {alert}
      </div>
    </div>
  );
};

export default Profile;
