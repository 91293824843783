import React from 'react';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';
import { Link } from 'react-router-dom';
import './RecorverPassword.css';

class RecoverPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      errorMessage: null,
    };
  }

  handleChange(event) {
    const target = event.target;
    const value = formatter.formatFromTarget(target);
    const name = target.name;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  async handleSubmit(event) {
    event.preventDefault();

    try {
      await apiHelper.resetPassword({
        email: this.state.email,
      });
      this.props.history.push('/login');
    } catch (error) {
      const statusCode = error.response.status;
      let errorMessage;

      if (statusCode === 401) {
        errorMessage = 'Email o contraseña invalidas';
      } else {
        errorMessage = error.message;
      }

      this.setState({
        errorMessage: errorMessage,
      });
    }
  }

  render() {
    let alert;

    if (this.state.errorMessage) {
      alert = (
        <div className="alert alert-warning">{this.state.errorMessage}</div>
      );
    }

    return (
      <div className="container">
        <div className="wrapper fadeInDown">
          <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
            <div className="fadeIn first">
              <h2 className="text-center mt-2">¿Olvidaste tu contraseña?</h2>
              <p>Ingresa tu direccion de email para recuperarla</p>
              <div className="panel-body">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      placeholder="Dirección de Email"
                      className="form-control"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleChange.bind(this)}
                      required
                      autoFocus
                    />
                  </div>
                </div>

                {alert}

                <button className="btn btn-primary btn-block" type="submit">
                  Recuperar contraseña
                </button>
              </div>

              <hr />

              <Link to="/login">Volver al Login</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default RecoverPassword;
