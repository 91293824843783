import React, { useState, useEffect } from 'react';
import apiHelpers from '../../utils/apiHelpers';
import CardPrice from '..//card/CardPrice';
import cardUtils from '../../utils/cardUtils';

const ShoppingCartItem = props => {
  const [cardImage, setCardImage] = useState('');
  const [cardURL, setCardURL] = useState('');

  useEffect(() => {
    let currCardName = props.card.name;
    setTimeout(function () {
      if (currCardName == props.card.name)
        apiHelpers.getCardImageByName(props.card.name).then(imageResult => {
          if (
            imageResult.data &&
            imageResult.data.image_uris &&
            imageResult.data.image_uris.normal
          )
            setCardImage(imageResult.data.image_uris.normal);
          if (imageResult.data && imageResult.data.scryfall_uri)
            setCardURL(imageResult.data.scryfall_uri);
        });
    }, 500);
  }, [props.card.name]);

  return (
    <div className="col-sm-1 col-md-4 col-lg-4 mb-3">
      <div className="card box-shadow text-white">
        <div className="card-header">
          <a href={cardURL} target="_blank">
            <h4 className="card-title text-grey">{props.card.name}</h4>
            <img className="card-image" src={cardImage ? cardImage : ''}></img>
          </a>
        </div>
        {cardUtils.sortPrices(props.card.cardPrices)[0] && (
          <div className="card-body">
            <CardPrice
              card={props.card}
              price={cardUtils.sortPrices(props.card.cardPrices)[0]}
              removeFromCart={props.onRemoveCardFromCart}></CardPrice>
          </div>
        )}
      </div>
    </div>
  );
};

export default ShoppingCartItem;
