import React, { useState, useEffect } from 'react';
import CardName from '../findCard/CardName';
import CardPurchaseItem from './CardPurchaseItem';
import cardUtils from '../../../utils/cardUtils';
import formatter from '../../../utils/formatter';

const CardPurchaseList = props => {
  const [showList, setShowList] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setTotalAmount(
      props.cards
        .map(card => cardUtils.sortPrices(card.cardPrices)[0].price)
        .reduce((prev, curr) => prev + curr, 0),
    );
  }, [props.cards]);

  return (
    <>
      {props.store && (
        <div className="store-div mb-3" onClick={e => setShowList(!showList)}>
          <span className="store-title text-grey ml-2">
            Cartas a adquirir en {props.store} (Total:{' '}
            {formatter.formatNumberDecimals(totalAmount)}{' '}
            {cardUtils.sortPrices(props.cards[0].cardPrices)[0].currency})
          </span>
          {showList ? (
            <i className="pull-right fas fa-angle-double-up mt-2 mr-2"></i>
          ) : (
            <i className=" pull-right fas fa-angle-double-down mt-2 mr-2"></i>
          )}
        </div>
      )}
      {showList && (
        <div className="row overlay">
          {props.showCards
            ? props.cards.map(card => (
                <CardPurchaseItem card={card}></CardPurchaseItem>
              ))
            : props.cards.map(card => <CardName card={card}></CardName>)}
        </div>
      )}
    </>
  );
};

export default CardPurchaseList;
