import apiHelpers from './apiHelpers';

const VMAGIC_SHOPPING_CART = 'vmagic_shopping_cart';
const VMAGIC_STORES = 'vmagic_stores';
const VMAGIC_USD_PRICE = 'vmagic_usd_price';

async function updateUSDPrice() {
  try {
    apiHelpers.getUSDPrice().then(res => {
      localStorage.setItem(VMAGIC_USD_PRICE, res.data.blue.value_avg);
      return res.data.blue.value_avg;
    });
  } catch (e) {}
  return;
}

function getUSDPrice() {
  return localStorage.getItem(VMAGIC_USD_PRICE);
}

async function updateStores() {
  let stores;
  try {
    apiHelpers.getStores().then(res => {
      localStorage.setItem(VMAGIC_STORES, JSON.stringify(res.data));
      return JSON.stringify(res.data);
    });
  } catch (e) {
    return [];
  }
  return stores;
}

function getStores() {
  let stores;
  try {
    stores = JSON.parse(localStorage.getItem(VMAGIC_STORES));
    if (stores == undefined) {
      apiHelpers
        .getStores()
        .then(res =>
          localStorage.setItem(VMAGIC_STORES, JSON.stringify(res.data)),
        );
      return [];
    }
  } catch (e) {
    apiHelpers
      .getStores()
      .then(res =>
        localStorage.setItem(VMAGIC_STORES, JSON.stringify(res.data)),
      );
    return [];
  }
  return stores;
}

function getStore(name) {
  let stores;
  try {
    stores = JSON.parse(localStorage.getItem(VMAGIC_STORES));
    if (stores == undefined) {
      apiHelpers
        .getStores()
        .then(res =>
          localStorage.setItem(VMAGIC_STORES, JSON.stringify(res.data)),
        );
      return;
    }
  } catch (e) {
    apiHelpers
      .getStores()
      .then(res =>
        localStorage.setItem(VMAGIC_STORES, JSON.stringify(res.data)),
      );
    return;
  }
  return stores.find(store => store.store == name);
}

function getShoppingCart() {
  let shoppingCartCards = JSON.parse(
    localStorage.getItem(VMAGIC_SHOPPING_CART),
  );
  if (!shoppingCartCards) shoppingCartCards = [];
  return shoppingCartCards;
}

function addShoppingCartCard(card, cardPrice) {
  let shoppingCartCards = JSON.parse(
    localStorage.getItem(VMAGIC_SHOPPING_CART),
  );
  if (!shoppingCartCards) shoppingCartCards = [];
  card.cardPrices = [cardPrice];
  shoppingCartCards.push(card);
  localStorage.setItem(VMAGIC_SHOPPING_CART, JSON.stringify(shoppingCartCards));
  return shoppingCartCards;
}

function removeShoppingCartCard(card) {
  let shoppingCartCards = JSON.parse(
    localStorage.getItem(VMAGIC_SHOPPING_CART),
  );
  if (!shoppingCartCards) shoppingCartCards = [];
  shoppingCartCards = shoppingCartCards.filter(
    existingCard => existingCard.id != card.id,
  );
  localStorage.setItem(VMAGIC_SHOPPING_CART, JSON.stringify(shoppingCartCards));
  return shoppingCartCards;
}

function emptyShoppingCart() {
  localStorage.setItem(VMAGIC_SHOPPING_CART, JSON.stringify([]));
  return [];
}

export default {
  updateUSDPrice,
  getUSDPrice,
  updateStores,
  getStores,
  getStore,
  getShoppingCart,
  addShoppingCartCard,
  removeShoppingCartCard,
  emptyShoppingCart,
};
