import React, { useState, useEffect } from 'react';
import ShoppingCart from '../../utils/shoppingCart';

const EmptyCartButton = props => {
  const [disabled, setDisabled] = useState(false);

  return (
    <button
      type="button"
      className="btn butt text-white w-100"
      onClick={e => {
        ShoppingCart.emptyShoppingCart();
        props.onEmptyCart();
      }}>
      Vaciar carrito<i className="fas fa-trash ml-2"></i>
    </button>
  );
};

export default EmptyCartButton;
