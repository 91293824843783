import React from 'react';
import { useEffect, useState } from 'react';
import Select from 'react-select';

const SelectCardLanguage = props => {
  const [options, setOptions] = useState([
    { value: null, label: 'Todos' },
    { value: 'ESP', label: 'Español' },
    { value: 'ING', label: 'Inglés' },
    { value: 'POR', label: 'Portugués' },
    { value: 'ITA', label: 'Italiano' },
    { value: 'FRA', label: 'Francés' },
    { value: 'ALE', label: 'Alemán' },
    { value: 'RUS', label: 'Ruso' },
    { value: 'CHI', label: 'Chino' },
    { value: 'JAP', label: 'Japonés' },
    { value: 'KOR', label: 'Coreano' },
  ]);

  const onChangeWrapper = value => {
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-nameless';
    event.target.value = value.value;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  let value = props.value;

  if (value) {
    value = options.find(option => option.value === value);
  }

  return (
    <Select
      className={props.selectClassName}
      name={props.name}
      onChange={onChangeWrapper.bind(this)}
      value={value || ''}
      required={props.required}
      options={options}
      clearable={false}
      placeholder={props.placeholder}
      menuContainerStyle={{ zIndex: 999 }}
      disabled={props.disabled}
      enuContainerStyle={{ zIndex: 999 }}
    />
  );
};

export default SelectCardLanguage;
