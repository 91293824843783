import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './SideMenu.css';
import apiHelper from '../../utils/apiHelpers';

const NewAside = () => {
  const [user, setUser] = useState({ first_name: '', last_name: '' });
  const [collapsed, setCollapsed] = useState(true);
  const [background, setBackground] = useState(false);

  const onClickExpand = () => {
    if (!collapsed) {
      setCollapsed(true);
      setBackground(false);
    } else {
      setCollapsed(false);
      setBackground(true);
    }
  };

  const notBackground = () => {
    setBackground(false);
    setCollapsed(true);
  };

  return (
    <>
      {!collapsed ? (
        <div
          className="backAside fadeInA"
          onClick={notBackground}
          background={background}></div>
      ) : null}
      <nav className={`sidebar ${collapsed ? 'active' : ''}`}>
        <div className="sidebar-header">
          <img
            src={process.env.PUBLIC_URL + '/img/logo.png'}
            width="170px"
            alt="vMagic"
          />
          <span className="pl-2 ">
            {user.first_name} {user.last_name}
          </span>
        </div>
        <ul className="list-unstyled components">
          <li
            onClick={e => {
              setCollapsed(true);
              setBackground(false);
            }}>
            <Link to={`${process.env.PUBLIC_URL}/findcard`}>
              <i className="fas fa-search  mr-2 "></i> Buscar carta
            </Link>
          </li>
          <li
            onClick={e => {
              setCollapsed(true);
              setBackground(false);
            }}>
            <Link to={`${process.env.PUBLIC_URL}/findbulk`}>
              <i className="fas fa-list-ol  mr-2 "></i> Buscar lista de cartas
            </Link>
          </li>
          <li
            onClick={e => {
              setCollapsed(true);
              setBackground(false);
            }}>
            <Link to={`${process.env.PUBLIC_URL}/wishlist`}>
              <i className="fas fa-heart  mr-2 "></i> Wishlist
            </Link>
          </li>
          <li
            onClick={e => {
              setCollapsed(true);
              setBackground(false);
            }}>
            <Link to={`${process.env.PUBLIC_URL}/shoppingcart`}>
              <i className="fas fa-shopping-cart mr-2 "></i> Carrito de compras
            </Link>
          </li>
          <li
            onClick={e => {
              setCollapsed(true);
              setBackground(false);
            }}>
            <Link to={`${process.env.PUBLIC_URL}/profile`}>
              <i className="fas fa-user  mr-2 "></i> Perfil
            </Link>
          </li>

          {apiHelper.isAdmin() && (
            <>
              <hr />
              <li>
                <Link onClick={e => apiHelper.crawl('')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar precios
                  (todos)
                </Link>
              </li>
              <li>
                <Link onClick={e => apiHelper.crawl('tcgplayer')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar
                  TCGPlayer
                </Link>
              </li>
              <li>
                <Link onClick={e => apiHelper.crawl('arkana')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar Arkana
                </Link>
              </li>
              <li>
                <Link onClick={e => apiHelper.crawl('labatikueva')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar La
                  Batikueva
                </Link>
              </li>
              <li>
                <Link onClick={e => apiHelper.crawl('magiclair')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar
                  MagicLair
                </Link>
              </li>
              <li>
                <Link onClick={e => apiHelper.crawl('magicz')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar MagicZ
                </Link>
              </li>
              <li>
                <Link onClick={e => apiHelper.crawl('mtgpirulo')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar MTG
                  Pirulo
                </Link>
              </li>
              <li>
                <Link onClick={e => apiHelper.crawl('mercadiacity')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar
                  MercadiaCity
                </Link>
              </li>
              <li>
                <Link onClick={e => apiHelper.crawl('magicdealers')}>
                  <i className="fas fa-database  mr-2 "></i> Actualizar
                  MagicDealers
                </Link>
              </li>
            </>
          )}
          <hr />
          <li>
            <Link to="/login" onClick={apiHelper.logout}>
              <i className="fas fa-sign-out-alt  mr-2 "></i> Cerrar sesión
            </Link>
          </li>
        </ul>
      </nav>
      <button
        type="button"
        collapsed={collapsed}
        className={`btn asideButton   ${collapsed ? 'activeButton' : ''}`}
        onClick={onClickExpand}>
        <i
          className={`${
            collapsed ? 'fas fa-align-center' : ' fas fa-arrow-circle-right '
          }`}></i>
      </button>
    </>
  );
};

export default NewAside;
